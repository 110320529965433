<template>
    <Image src="layout/images/duon_azul.png" alt="Logo formando a palavra Duon" class="my-4" width="80" />
    <Image src="layout/images/agendamento/agendamentoConfirmado.svg" alt="Logo formando a palavra Duon" width="200" />
    <div
        v-if="aguardandoConfirmacaoPrestador || (emAberto && !ordemDeChagada)"
        class="font-bold mt-2 text-center poppins-medium"
        style="font-size: 24px"
    >
        Sua consulta está sendo processada
    </div>
    <div v-if="agendado" class="font-bold text-center poppins-medium mt-4" style="font-size: 24px">Sua consulta foi agendada com sucesso</div>
    <div v-if="aguardandoConfirmacaoPrestador" class="w-full flex flex-column p-4 mt-6 border-round-xl text-2xl" style="background-color: #f1f5f9">
        A sua solicitação será direcionada ao prestador solicitado, aguarde a confirmação do agendamento, em breve teremos maiores informações
    </div>
    <div v-if="emAberto" class="w-full flex flex-column p-4 mt-6 border-round-xl text-2xl" style="background-color: #f1f5f9">
        Sua solicitação foi direcionada para um atendente. Assim que a sua solicitação for concluída, enviaremos os detalhes do agendamento para o seu
        WhatsApp.
    </div>
    <div v-if="agendado" class="w-full flex flex-column p-4 mt-4 border-round-xl text-2xl" style="background-color: #f1f5f9">
        <div class="flex align-items-center my-2">
            <i class="pi pi-file-edit mr-3 text-3xl" style="color: #3f51b5" />
            {{ agendamento.tipoExame }}
        </div>
        <div class="flex align-items-center my-2">
            <i class="pi pi-file mr-3 text-3xl" style="color: #3f51b5" />
            {{ agendamento.procedimentos }}
        </div>
        <div class="flex align-items-center my-2">
            <i class="pi pi-calendar mr-3 pink text-3xl" style="color: #3f51b5" />
            {{ agendamento.data }}
        </div>
        <div class="flex align-items-center my-2">
            <i class="pi pi-clock mr-3 text-3xl" style="color: #3f51b5" />
            {{ ordemDeChagada ? 'Por ordem de chegada a partir das' : '' }}
            {{ agendamento.horario }}
        </div>
        <div class="flex align-items-center my-2">
            <i class="pi pi-map-marker mr-3 text-3xl" style="color: #3f51b5" />
            {{ agendamento.local }}
        </div>
    </div>
    <div v-if="agendado" class="flex align-items-center w-full mt-4">
        <InputSwitch v-model="receberLembrete" id="lembrete" />
        <label for="lembrete" class="text-2xl ml-3 poppins-medium"> Receber lembrete por e-mail </label>
    </div>
    <InputText
        v-if="agendado && receberLembrete"
        v-model="agendamento.emailFuncionario"
        class="w-full border-2 border-round-xl p-4 mt-1 text-xl font-medium"
        placeholder="e-mail"
        type="mail"
    />
    <Button
        v-if="agendado && receberLembrete"
        :disabled="!agendamento.emailFuncionario"
        :loading="loading"
        class="border-none text-xl w-full mt-auto mb-5 py-3 poppins-medium"
        label="Receber Lembrete"
        rounded
        style="background: #3f51b5"
        @click="enviarLembrete()"
    />
</template>

<script setup>
import { defineEmits, onActivated, ref, computed } from 'vue';
import { getAutoagendamentoToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutAutoagendamento } from '@/services/auth';
import EnumTipoAtendimento from '@/enums/EnumTipoAtendimento';
import { useToast } from 'primevue/usetoast';
import StatusAgendamento from '../../../enums/StatusAgendamento';
import { showSuccess } from '@/utils/Toast';

defineEmits(['prevPage', 'nextPage']);

const autoagendamentoToken = getAutoagendamentoToken();
const auxHeaders = { Authorization: `Bearer ${autoagendamentoToken}` };

const toast = useToast();
const loading = ref(false);
const receberLembrete = ref(false);
const agendamento = ref({
    status: null,
    tipoExame: null,
    tipoAtendimento: null,
    procedimentos: null,
    idFuncionario: null,
    emailFuncionario: null,
    data: null,
    horario: null,
    local: null
});

const ordemDeChagada = computed(() => {
    return agendamento.value.tipoAtendimento === EnumTipoAtendimento.ORDEM_DE_CHEGADA;
});

const agendado = computed(() => {
    return agendamento.value.status === StatusAgendamento.AGENDADO;
});
const emAberto = computed(() => {
    return agendamento.value.status === StatusAgendamento.EM_ABERTO;
});
const aguardandoConfirmacaoPrestador = computed(() => {
    return agendamento.value.status === StatusAgendamento.AGUARDANDO_CONFIRMACAO_DO_PRESTADOR;
});

onActivated(() => {
    buscarDadosDoAgendamento();
});

async function buscarDadosDoAgendamento() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/dados-agendamento');
        agendamento.value = data;
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
async function enviarLembrete() {
    loading.value = true;
    try {
        await getClientBase(auxHeaders).patch('/autoagendamento/colaborador', {
            id: agendamento.value.idFuncionario,
            email: agendamento.value.emailFuncionario
        });
        await getClientBase(auxHeaders).post('/autoagendamento/envio-agenda-colaborador');

        receberLembrete.value = false;
        showSuccess(toast, 'Lembrete enviado com sucesso');
    } catch (error) {
        const { message, statusCode } = error?.response?.data;
        if (statusCode === 401) logoutAutoagendamento();
        toast.add({
            severity: 'error',
            summary: message,
            life: 3000
        });
    }
    loading.value = false;
}
</script>
